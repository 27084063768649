import React from 'react'
import { Box, Heading, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'

const GDPRPage = () => {
  return (
    <Layout headerTitle="Autovraky Hrubeš" footerTitle="Ekologická likvidace vozidel Ostrava">
      <SEO title="Zpracování osobních údajů" />

      <Box
        sx={{
          py: 5,
          '& p, & ul': { mb: 3 },
          '& ul': { pl: 4, listStyle: 'disc' },
        }}
      >
        <Container>
          <Heading as="h1" variant="sectionTitle" sx={{ mb: 4 }}>
            Souhlas se zpracováním osobních údajů
          </Heading>

          <Text as="p">
            <strong>
              Já, níže podepsaná/ý souhlasím se zpracováním svých osobních údajů
              firmou Filip Hrubeš, se sídlem Žilinská 1320/7, 708 00,
              Ostrava-Poruba, IČ: 03880397 (dále jen „společnost“) v rozsahu
              těchto údajů:
            </strong>
          </Text>

          <Box as="ul">
            <li>E-mailová adresa</li>
            <li>Telefon</li>
            <li>Jméno</li>
            <li>Příjmení</li>
          </Box>

          <Text as="p">
            Souhlas je platný pouze v případě, že mé osobní údaje budou
            zpracovávány pouze v rozsahu nezbytném pro dosažení účelu zpracování
            uvedeného níže a v souladu s příslušnou legislativou.
          </Text>

          <Text as="p">
            <strong>Souhlas je poskytnut za účelem:</strong>
          </Text>

          <Box as="ul">
            <li>Zaslaní a kontaktovaní nabídkou na základě poptávky</li>
          </Box>

          <Text as="p">
            <strong>
              Souhlasím se zpracováním svých osobních údajů Společností po dobu:
            </strong>
          </Text>

          <Box as="ul">
            <li>Po dobu do odvolání souhlasu se zpracováním.</li>
          </Box>

          <Text as="p">
            <strong>
              Souhlasím se zpřístupněním svých osobních údajů Společnosti:
            </strong>
          </Text>

          <Box as="ul">
            <li>
              <Text as="p">
                Společnost je oprávněna použít mé osobní údaje pouze v souladu s
                výše uvedeným účelem, nebo pro legitimní potřebu státních
                kontrolních orgánů a orgánů činných v trestním řízení.
              </Text>
              <Text as="p">
                Společnost je dále oprávněna poskytnout mé osobní údaje pouze
                subjektům spolupracujícím se Společností na dosažení primárního
                účelu, pro který je udělen tento souhlas. S takovými subjekty se
                Společností zavazuje uzavřít smlouvu obsahující stejné podmínky
                pro zpracování mých osobních údajů.
              </Text>
            </li>
          </Box>

          <Text as="p">
            Zpracování bude probíhat v souladu s příslušnými právními normami o
            ochraně osobních údajů a s Nařízením Evropského parlamentu a
            Rady(EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v
            souvislosti se zpracováním osobních údajů a o volném pohybu těchto
            údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně
            osobních údajů)
          </Text>

          <Text as="p">
            Byl/a jsem poučen/a o tom, že poskytnutí údajů je dobrovolné. Dále
            jsem byl/a v souladu s příslušnou legislativou poučen/a:
          </Text>

          <Box as="ul">
            <li>o svém právu tento souhlas odvolat a to i bez udání důvodu,</li>
            <li>
              o svém právu přístupu ktěmto údajům a právu na jejich opravu,
            </li>
            <li>
              o svém právu na vymazání těchto údajů, pokud dochází k jejich
              zpracování v rozporu s ochranou definovanou příslušnou
              legislativou nebo v rozporu s tímto souhlasem, nebo byl souhlas
              odvolán.
            </li>
          </Box>

          <Text as="p">
            Byl/a jsem také poučen o tom, že tato svá práva mohu uplatnit
            doručením žádosti na e-mailovou adresu{' '}
            <a href="mailto:info@autovrakyhrubes.cz">info@autovrakyhrubes.cz</a>
            .
          </Text>

          <Text as="p">
            Beru na vědomí, že odvolání tohoto souhlasu může ovlivnit dosažení
            účelu, pro který byl tento souhlas vydán, pokud tento účel nelze
            dosáhnout jinak.
          </Text>
        </Container>
      </Box>
    </Layout>
  )
}

export default GDPRPage
